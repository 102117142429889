<!--
 * @Description: AKJERRT
 * @Date: 2022-07-08 22:12:44
 * @LastEditTime: 2022-07-14 11:39:29
 * @FilePath: \linkgap-front\src\views\dealer_balance\components\table\overview.vue
-->
<template>
  <a-modal title="对账总览" width="60%" v-model="visible" :footer="null" :maskClosable="false">
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
      <a-row :gutter="8">
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="对账时间">
            <a-date-picker format="YYYY-MM" placeholder="请选择对账时间" />
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-button type="primary" @click="query()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <br />
    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="false"
    >
      <span slot="YF" slot-scope="text">
        <span @click="hanlderRouteGo('ZHZL')" class="date-month"> {{ text }} </span>
      </span>
    </a-table>
    <br />
    <ZLAccountTable ref="ZLAccountTable" />
  </a-modal>
</template>

<script>
import ZLAccountTable from './ZLAccountTable.vue'
export default {
  components: {
    ZLAccountTable,
  },
  data() {
    return {
      tableLoading: false,
      visible: false,
      tableData:[],
      columns: [
        {
          title: '月份',
          dataIndex: 'YF',
          key: 'YF',
          align: 'center',
          className: 'headerSkyBlue',
          scopedSlots: { customRender: 'YF' },
        },
        {
          title: '对账',
          align: 'center',
          dataIndex: 'DZ',
          key: 'DZ',
        },
        {
          title: '对账人',
          align: 'center',
          dataIndex: 'DZR',
          key: 'DZR',
        },
        {
          title: '对账时间',
          align: 'center',
          dataIndex: 'DZSJ',
          key: 'DZSJ',
        },
      ],
    }
  },
  mounted() {
    this.tableData = [
      {
        YF: '2022年1月',
        DZ: '相符',
        YF: '江苏艾康迪机电工程有限公司',
        YF: '2022/2/5',
      },
    ]
  },
  methods: {
    hanlderRouteGo(type) {
      if (type === 'ZHZL' && this.$refs.ZLAccountTable) {
        this.$refs.ZLAccountTable.setRowData()
      }
    },
    setRowData() {
      this.visible = true
    },
    toSubmit() {
      this.visible = false
    },
    cancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
.footer-btns {
  text-align: right;
}
.title {
  font-size: 1.5rem;
  font-weight: 900;
  .title-company {
    border-bottom: 1px double black;
    cursor: pointer;
  }
}
::v-deep .ant-table-thead > tr > th.headerYellow {
  background-color: #fff2cc;
}
::v-deep .ant-table-thead > tr > th.headerBlue {
  background-color: #ddebf7;
}
::v-deep .ant-table-thead > tr > th.headerTextRed {
  color: red;
  font-weight: 600;
}
.TextRed {
  color: red;
}
.annotation {
  color: orange;
  font-size: 12px;
}
.date-month {
  color: skyblue;
  text-decoration: underline;
  cursor: pointer;
}
</style>
